<template>
  <v-card class="pa-0 mb-0" tile>
    <Loader v-if="loading"/>
    <v-form v-else ref="form" v-model="valid">
      <v-card-title>
        <h4 class="primary--text">
          {{ $t("vehicle.title") }}
        </h4>
        <v-spacer />
        <div>
          <div class="float-left">
          <v-select class="template-select"
              v-model="selectedTemplate"
              :items="templates"
              item-text="name"
              item-value="name"
              dense
              outlined
              @change="onTemplateChange"
              clearable />
            </div>
          <v-btn v-if="selectedTemplate" :loading="loading" :disabled="loading" color="primary" outlined depressed class="mr-4" @click="deleteTemplate">
            {{ $t('freight.deleteTemplate') }}
          </v-btn>
          <v-btn :loading="loading" :disabled="loading" color="primary" outlined depressed class="mr-4" @click="openSaveTemplate">
            {{ $t('freight.saveTemplate') }}
          </v-btn>
          <v-btn color="primary" outlined depressed class="mr-4" :disabled="loadingForm" @click="$router.go(-1)">
            {{ $t("vehicle.cancel") }}
          </v-btn>
          <v-btn color="primary" outlined depressed @click="submit" :loading="loadingForm" :disabled="loadingForm">
            {{ $t("vehicle.publish") }}
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-divider class="mt-2 mb-4" />
        <v-row class="mb-0">
          <v-col cols="12">
            <v-card outlined class="v-card-outline-primary" elevation="3">
              <v-card-title>
                <h5 class="primary--text">{{ $t("vehicle.details") }}</h5>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12">
                        <label for="vehicleSizeType">{{ $t("vehicle.vehicleSizeType") }}</label>
                        <v-select
                          v-model="form.size"
                          ref="vehicleSizeType"
                          id="vehicleSizeType"
                          :items="vehicleSizeTypes"
                          item-text="description"
                          item-value="id"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                          hide-details="auto"
                          :rules="[required]"
                          @input="blurSelect"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <label for="vehicleType">{{ $t("vehicle.vehicleType") }}</label>
                        <v-select
                          v-model="form.type"
                          ref="vehicleType"
                          id="vehicleType"
                          :items="vehicleTypes"
                          item-text="description"
                          item-value="id"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                          hide-details="auto"
                          :rules="[required]"
                          @input="blurSelect"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12">
                        <label for="load">{{ $t('vehicle.loadForm') }}</label>
                        <v-text-field
                          v-model.number="form.load"
                          id="load"
                          type="number"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                          hide-details="auto"
                          :rules="[required, onlyNaturalNumbers, fractionDigitsValidation]"
                        />
                      </v-col>
                      <v-col cols="12">
                        <label for="loadMeters">{{ $t('vehicle.loadMeters') }}</label>
                        <v-text-field
                          v-model.number="form.loadMeters"
                          id="loadMeters"
                          type="number"
                          outlined
                          validate-on-blur
                          dense
                          single-line
                          hide-details="auto"
                          :rules="[onlyNaturalNumbers, fractionDigitsValidation]"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <v-btn color="primary" outlined depressed @click="showMoreDetailsClick">{{ showMoreDetailsBtnText }}</v-btn>
                  </v-col>
                  <template v-if="showMoreDetails">
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" md="4">
                          <label for="height">{{ $t('vehicle.height') }}</label>
                          <v-text-field
                            v-model.number="form.height"
                            id="height"
                            type="number"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            hide-details="auto"
                            :rules="[onlyNaturalNumbers]"
                            @change="calculateVolumeChange"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <label for="width">{{ $t('vehicle.width') }}</label>
                          <v-text-field
                            v-model.number="form.width"
                            id="width"
                            type="number"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            hide-details="auto"
                            :rules="[onlyNaturalNumbers]"
                            @change="calculateVolumeChange"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <label for="length">{{ $t('vehicle.length') }}</label>
                          <v-text-field
                            v-model.number="form.length"
                            id="length"
                            type="number"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            hide-details="auto"
                            :rules="[onlyNaturalNumbers]"
                            @change="calculateVolumeChange"
                          />
                        </v-col>
                        <v-col cols="12">
                          <label for="volume">{{ $t('vehicle.volume') }}</label>
                          <v-text-field
                            v-model.number="form.volume"
                            id="volume"
                            type="number"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            hide-details="auto"
                            :rules="[onlyNaturalNumbers, fractionDigitsValidation]"
                            :disabled="isCalculatedVolume"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12">
                          <label for="options">{{ $t('vehicle.options') }}</label>
                          <v-select
                            v-model="form.options"
                            id="options"
                            :items="vehicleOptions"
                            item-text="description"
                            item-value="id"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            multiple
                            hide-details="auto"
                            @input="blurSelect"
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <label for="loadForms">{{ $t('vehicle.loadForms') }}</label>
                          <v-select
                            v-model="form.loadForms"
                            id="loadForms"
                            :items="vehicleLoadFormTypes"
                            item-text="description"
                            item-value="id"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            multiple
                            hide-details="auto"
                            @input="blurSelect"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card outlined class="v-card-outline-primary" elevation="3">
              <v-row>
                <v-col cols="6">
                  <v-card-title>
                    <h5 class="primary--text">{{ $t('vehicle.load') }}</h5>
                  </v-card-title>
                  <v-card-text class="pr-md-0">
                    <v-row>
                      <v-col cols="12">
                        <div class="d-flex" v-for="(item, i) in form.loadingPlaces" :key="i">
                          <LocationInput
                            v-model="item.value"
                            :label="$t('vehicle.address')"
                            lg="3"
                            class="mb-4"
                            validation
                            hide-range
                            :allowOnlyCountry="false"
                          />
                          <v-btn
                            icon
                            class="mt-4 ml-1"
                            :disabled="i === 0"
                            @click="removeLoadingPlaces(item)"
                          >
                            <v-icon>$trash</v-icon>
                          </v-btn>
                        </div>
                        <v-btn color="primary" outlined :disabled="form.loadingPlaces.length > 4" @click="addLoadingPlaces">
                          {{ $t("common.add") }}
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <DatePicker
                          v-model="startDate"
                          :label="$t('vehicle.dateFrom')"
                          :min="minDate"
                          :error="dataRangeError"
                          :error-messages="dataRangeErrorMessage"
                          :resetButton="false"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox v-model="enableLoadHour" hide-details class="mt-0">
                          <template #label>
                            <span>{{ $t('vehicle.setTime') }}</span>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col v-if="enableLoadHour" cols="12">
                        <HourInput
                          v-model="startTime"
                          :label="$t('vehicle.timeFrom')"
                          :error="startTimeError"
                          :error-messages="startTimeErrorMessage"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-title class="pl-md-0">
                    <h5 class="primary--text">{{ $t('vehicle.unload') }}</h5>
                  </v-card-title>
                  <v-card-text class="pl-md-0">
                    <v-row>
                      <v-col cols="12">
                        <div v-for="(item, i) in form.unloadingPlaces" :key="i" class="d-flex align-center">
                          <LocationInput
                            v-model="item.value"
                            :label="$t('vehicle.address')"
                            lg="3"
                            class="mb-4"
                            hide-range
                          />
                          <v-btn icon class="ml-1" @click="removeUnloadingPlaces(item)">
                            <v-icon>$trash</v-icon>
                          </v-btn>
                        </div>
                        <v-btn color="primary" outlined :disabled="form.unloadingPlaces.length > 4" @click="addUnloadingPlaces">
                          {{ $t("common.add") }}
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <DatePicker
                          v-model="endDate"
                          :label="$t('vehicle.dateTo')"
                          :min="startDate"
                          :error="dataRangeError"
                          :error-messages="dataRangeErrorMessage"
                          @reset="endDateReset"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox v-model="enableUnloadHour" hide-details :disabled="!this.endDate" class="mt-0">
                          <template #label>
                            <span>{{ $t('vehicle.setTime') }}</span>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col v-if="enableUnloadHour" cols="12">
                        <HourInput
                          v-model="endTime"
                          :label="$t('vehicle.timeTo')"
                          :error="endTimeError"
                          :error-messages="endTimeErrorMessage"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined class="v-card-outline-primary" elevation="3">
              <v-row>
                <v-col cols="12">
                  <v-card-title>
                    <h5 class="primary--text">{{ $t('vehicle.additionalInformation') }}</h5>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12">
                            <label for="additionalDescription">{{ $t('vehicle.additionalDescription') }}</label>
                            <v-textarea
                              v-model="form.additionalDescription"
                              id="additionalDescription"
                              outlined
                              required
                              validate-on-blur
                              dense
                              single-line
                              no-resize
                              hide-details
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" md="6">
                            <label for="value">{{ $t('vehicle.value') }}</label>
                            <v-text-field
                              v-model.number="form.price"
                              id="value"
                              type="number"
                              outlined
                              required
                              validate-on-blur
                              dense
                              single-line
                              hide-details="auto"
                              :rules="[onlyNaturalNumbers, valueFractionDigitsValidation]"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <label for="currency">{{ $t('vehicle.currency') }}</label>
                            <v-select
                              v-model="form.currency"
                              ref="currency"
                              id="currency"
                              :items="allCurrencies"
                              item-text="description"
                              item-value="id"
                              outlined
                              required
                              validate-on-blur
                              dense
                              single-line
                              hide-details
                              @input="blurSelect"
                            ></v-select>
                          </v-col>
                          <v-col cols="12">
                            <div class="d-flex">
                              <v-checkbox v-model="privateOffer" hide-details class="mt-0">
                                <template #label>
                                  <span>{{ $t('vehicle.privateOffer') }}</span>
                                </template>
                              </v-checkbox>
                            </div>
                          </v-col>
                          <template v-if="privateOffer">
                            <v-col cols="12">
                              <label for="limitedCompanies">{{ $t('vehicle.limitedCompanies') }}</label>
                              <AutoComplete
                                v-model="form.limitedCompanies"
                                :id="'limitedCompanies'"
                                :items="companies"
                                :item-text="'name'"
                                :item-value="'id'"
                              ></AutoComplete>
                            </v-col>
                            <v-col cols="12">
                              <label for="limitedCompaniesGroups">{{ $t('vehicle.limitedCompaniesGroups') }}</label>
                              <AutoComplete
                                v-model="form.limitedCompaniesGroups"
                                :id="'limitedCompaniesGroups'"
                                :items="companyGroups"
                                :item-text="'name'"
                                :item-value="'id'"
                              ></AutoComplete>
                            </v-col>
                            <v-col cols="12">
                              <label for="excludedCompaniesGroups">{{ $t('vehicle.excludedCompaniesGroups') }}</label>
                              <AutoComplete
                                v-model="form.excludedCompaniesGroups"
                                :id="'excludedCompaniesGroups'"
                                :items="companyGroups"
                                :item-text="'name'"
                                :item-value="'id'"
                              ></AutoComplete>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-4" />
        <v-card-actions v-if="!loading">
          <v-row justify="center">
            <v-col>
              <v-btn color="primary" outlined depressed class="px-6 float-right" :disabled="loadingForm"
                @click="$router.go(-1)">
                {{ $t("vehicle.cancel") }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="primary" outlined depressed @click="submit" :loading="loadingForm" :disabled="loadingForm">
                {{ $t("vehicle.publish") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-form>
    <v-dialog v-model="templateDialog" width="550" persistent>
      <v-card>
        <v-card-title>{{ $t('freight.saveTemplate') }}</v-card-title>
        <v-card-text>
          <label>{{ $t('freight.templateName') }}</label>
          <v-text-field
            :disabled="loading"
            :loading="loading"
            :rules="templateRules"
            v-model="templateName"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn :loading="loading" :disabled="loading || !templateNameCheck" color="primary" @click="saveTemplate" depressed>{{ $t('common.save') }}</v-btn>
          <v-btn :loading="loading" :disabled="loading" outlined @click="closeSaveTemplate" depressed>{{ $t('common.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import DatePicker from '@/components/forms/DatePicker'
import HourInput from '@/components/forms/HourInput'
import AutoComplete from '@/components/forms/AutoComplete.vue'
import Loader from '@/components/Loader'
import Service from '@/services'
import * as moment from 'moment'
import LocationInput from '@/components/forms/LocationInput'
import { catchMarketError, createNewPlace } from '@/utils/market'
import { fractionDigitsValidation, generateString, roundNumber } from '@/utils/helpers'

export default {
  name: 'AddVehicle',
  components: {
    DatePicker,
    HourInput,
    Loader,
    LocationInput,
    AutoComplete
  },
  data () {
    return {
      templateRules: [
        (v) =>
          (v && v.length <= 20) ||
          this.$t('freight.templateRule')
      ],
      templateDialog: false,
      templateName: '',
      selectedTemplate: null,
      loading: true,
      loadingForm: false,
      valid: true,
      showMoreDetails: false,
      form: {
        size: null,
        type: null,
        load: null,
        loadMeters: null,
        height: null,
        width: null,
        length: null,
        volume: null,
        options: [],
        loadForms: [],
        loadingPlaces: [
          { value: createNewPlace() }
        ],
        unloadingPlaces: [
          { value: createNewPlace() }
        ],
        start: moment().add(2, 'hour'),
        end: moment().add(3, 'hour'),
        additionalDescription: null,
        price: null,
        currency: this.$store.state.userCommonData.defaultCurrency,
        limitedCompanies: [],
        limitedCompaniesGroups: [],
        excludedCompaniesGroups: []
      },
      minDate: moment().format('YYYY-MM-DD'),
      // minStartTime: moment().add(1, 'hours').utc().startOf('hour').valueOf(),
      // minEndTime: moment().add(1, 'hours').utc().startOf('hour').valueOf(),
      startDate: moment().format('YYYY-MM-DD'),
      startTime: null,
      endDate: null,
      endTime: null,
      vehicleTypes: [],
      vehicleOptions: [],
      required: (v) => !!v || this.$t('validation.fieldRequired'),
      fractionDigitsValidation: (v) => fractionDigitsValidation(v, 3) || this.$t('validation.fractionDigitsValidation', { digits: 3 }),
      valueFractionDigitsValidation: (v) => fractionDigitsValidation(v, 2) || this.$t('validation.fractionDigitsValidation', { digits: 2 }),
      onlyNaturalNumbers: (v) => v ? v > 0 ? true : this.$t('validation.onlyNaturalNumbers') : true,
      // hourRange: () => moment(this.startDate).unix() === moment(this.endDate).unix() ? this.startTime > this.endTime ? this.$t('validation.invalidRange') : true : true,
      // startHourLaterNow: (v) => moment(this.startDate).unix() === moment().startOf('day').unix() ? moment().valueOf() > v ? this.$t('validation.hourOnlyLaterNow') : true : true,
      // endHourLaterNow: (v) => moment(this.endDate).unix() === moment().startOf('day').unix() ? moment().valueOf() > v ? this.$t('validation.hourOnlyLaterNow') : true : true,
      dataRange: () => moment(this.startDate).unix() > moment(this.endDate).unix() ? this.$t('validation.dateRange') : true,
      privateOffer: false,
      enableLoadHour: false,
      enableUnloadHour: false,
      companyGroups: [],
      templates: [],
      companies: [],
      isCalculatedVolume: false
    }
  },
  computed: {
    templateNameCheck () {
      return this.templateName.length <= 20
    },
    copyMode () {
      return this.$route.name === 'CopyVehicle'
    },
    vehicleSizeTypes () {
      return this.$store.getters.enums.vehicleSizeTypes
    },
    vehicleLoadFormTypes () {
      return this.$store.getters.enums.vehicleLoadFormTypes
    },
    allCurrencies () {
      return this.$store.getters.allCurrencies.filter(c => c.isActive)
    },
    startTimeError () {
      if (this.enableUnloadHour && moment(this.startDate).unix() === moment(this.endDate).unix()) {
        if (this.startTime > this.endTime) {
          return true
        }
      }

      if (moment(this.startDate).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.startTime) {
          return true
        }
      }
      return false
    },
    startTimeErrorMessage () {
      if (this.enableUnloadHour && moment(this.startDate).unix() === moment(this.endDate).unix()) {
        if (this.startTime > this.endTime) {
          return this.$t('validation.invalidRange')
        }
      }

      if (moment(this.startDate).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.startTime) {
          return this.$t('validation.hourOnlyLaterNow')
        }
      }
      return []
    },
    endTimeError () {
      if (this.enableLoadHour && moment(this.startDate).unix() === moment(this.endDate).unix()) {
        if (this.startTime > this.endTime) {
          return true
        }
      }

      if (moment(this.endDate).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.endTime) {
          return true
        }
      }
      return false
    },
    endTimeErrorMessage () {
      if (this.enableLoadHour && moment(this.startDate).unix() === moment(this.endDate).unix()) {
        if (this.startTime > this.endTime) {
          return this.$t('validation.invalidRange')
        }
      }

      if (moment(this.endDate).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.endTime) {
          return this.$t('validation.hourOnlyLaterNow')
        }
      }
      return []
    },
    dataRangeError () {
      if (moment(this.startDate).unix() > moment(this.endDate).unix()) {
        return true
      }
      return false
    },
    dataRangeErrorMessage () {
      if (moment(this.startDate).unix() > moment(this.endDate).unix()) {
        return this.$t('validation.dateRange')
      }
      return []
    },
    showMoreDetailsBtnText () {
      return this.showMoreDetails ? this.$t('vehicle.lessDetails') : this.$t('vehicle.moreDetails')
    },
    isNotEmptyUnloadPlace () {
      return this.form.unloadingPlaces.length > 0 && this.form.unloadingPlaces[0] && this.form.unloadingPlaces[0].value && this.form.unloadingPlaces[0].value.country && this.form.unloadingPlaces[0].value.country.length > 0
    }
  },
  watch: {
    isNotEmptyUnloadPlace (newValue) {
      if (newValue) {
        if (!this.endDate) this.endDate = moment().add(1, 'days').format('YYYY-MM-DD')
      } else {
        this.endDate = null
      }
    }
  },
  async created () {
    await this.fetchData()
    this.setStartTime()
    this.setEndTime()
    if (this.copyMode) {
      await this.fetchDetails()
    }
    this.loading = false
  },
  methods: {
    async onTemplateChange (templateName) {
      if (templateName === null) {
        this.form = {
          size: null,
          type: null,
          load: null,
          height: null,
          loadMeters: null,
          length: null,
          volume: null,
          options: [],
          loadForms: [],
          loadingPlaces: [
            { value: createNewPlace() }
          ],
          unloadingPlaces: [
            { value: createNewPlace() }
          ],
          start: moment().add(2, 'hour'),
          end: moment().add(3, 'hour'),
          additionalDescription: null,
          price: null,
          currency: this.$store.state.userCommonData.defaultCurrency,
          limitedCompanies: [],
          limitedCompaniesGroups: [],
          excludedCompaniesGroups: [],
          width: null
        }
        return
      }

      const orderDetails = this.templates.find(x => x.name === templateName).model
      try {
        const loadingPlaces = orderDetails.loadingPlaces.map(i => ({ value: i, id: generateString() }))
        const unloadingPlaces = orderDetails.unloadingPlaces.map(i => ({ value: i, id: generateString() }))

        const now = moment().format('YYYY-MM-DD')
        const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')

        if (now > this.convertDate(orderDetails.startDate)) {
          this.startDate = now
        } else {
          this.startDate = this.convertDate(orderDetails.startDate)
        }

        if (now > this.convertDate(orderDetails.endDate)) {
          this.endDate = tomorrow
        } else {
          this.endDate = this.convertDate(orderDetails.endDate)
        }

        this.form = {
          ...orderDetails,
          loadingPlaces,
          unloadingPlaces
        }

        this.form.limitedCompanies = []
      } catch (e) {
        await catchMarketError(e, true, () => {
          this.$router.push({ name: 'AddFreight' })
        })
      }
    },
    async saveTemplate () {
      if (this.$refs.form.validate()) {
        this.loading = true
        const loadingPlaces = this.form.loadingPlaces.map(i => ({ ...i.value }))
        const unloadingPlaces = this.form.unloadingPlaces
          .filter(i => i.value.country && i.value.country.length > 0)
          .map(i => ({ ...i.value }))

        if (!this.privateOffer) {
          this.form.limitedCompanies = []
          this.form.limitedCompaniesGroups = []
          this.form.excludedCompaniesGroups = []
        }

        const startTimeFormat = moment(this.startTime).format('HH:mm')
        const endTimeFormat = moment(this.endTime).format('HH:mm')

        let startDate = null
        let startTime = null

        if (this.enableLoadHour) {
          startDate = moment(this.startDate).format()
          startTime = moment(`${this.startDate} ${startTimeFormat}`).format()
        } else {
          startDate = moment(this.startDate).format()
        }

        let endDate = null
        let endTime = null

        if (this.enableUnloadHour) {
          endDate = moment(this.endDate).format()
          endTime = moment(`${this.endDate} ${endTimeFormat}`).format()
        } else {
          if (this.endDate) {
            endDate = moment(this.endDate).format()
          }
        }

        try {
          const model = {
            model: {
              ...this.form,
              loadingPlaces,
              unloadingPlaces,
              startDate,
              startTime,
              endDate,
              endTime
            },
            name: this.templateName
          }
          await Service.post('/VehicleOrder/AddTemplate', model)
          this.templates.push(model)
          var newModel = {
            name: this.templateName
          }
          this.selectedTemplate = newModel
        } catch (e) {
          await catchMarketError(e, true, () => {
          })
        } finally {
          this.loading = false
          this.templateName = ''
          this.templateDialog = false
        }
      }
    },
    openSaveTemplate () {
      if (this.$refs.form.validate()) {
        this.templateName = ''
        this.templateDialog = true
      }
    },
    async deleteTemplate () {
      this.loading = true
      try {
        var model = {
          name: this.selectedTemplate
        }
        await Service.delete('/VehicleOrder/DeleteTemplate', model)
        this.templates = this.templates.filter(x => x.name !== this.selectedTemplate)
        this.selectedTemplate = null
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    closeSaveTemplate () {
      this.templateName = ''
      this.templateDialog = false
    },
    setStartTime () {
      const now = moment()
      const startTime = moment().startOf('hour').add(15, 'minutes')
      let i = 0
      while (i < 4) {
        if (startTime.valueOf() > now.valueOf()) {
          this.startTime = startTime.valueOf()
          return
        } else {
          startTime.add(15, 'minutes')
        }
        i++
      }
    },
    setEndTime () {
      this.endTime = moment().set('hour', 8).set('minute', 0).valueOf()
    },
    async fetchData () {
      try {
        const { data: vehicleTypes } = await Service.get('Dictionary/GetVehicleTypes')
        const language = this.$store.getters.userCommonData.language
        this.vehicleTypes = vehicleTypes.map(type => {
          const item = {
            id: type.id,
            description: type.translatedValues.find(value => value.language === language).name
          }
          return item
        })

        const { data: vehicleOptions } = await Service.get('Dictionary/GetVehicleOptions')

        this.vehicleOptions = vehicleOptions.map(type => {
          const item = {
            id: type.id,
            description: type.translatedValues.find(value => value.language === language).name
          }
          return item
        })

        const { data: templates } = await Service.get('/VehicleOrder/GetTemplates')
        this.templates = templates

        const { data: companyGroups } = await Service.get('CompanyGroup/GetAll')
        this.companyGroups = companyGroups.results
        const { data: companies } = await Service.get('Company/GetCompanyNames')
        this.companies = companies
      } catch (e) {
      }
    },
    convertDate (date) {
      return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    },
    async fetchDetails () {
      try {
        const { data: orderDetails } = await Service.get('VehicleOrder/CopyFrom', { orderId: this.$route.params.id })
        const loadingPlaces = orderDetails.loadingPlaces.map(i => ({ value: i, id: generateString() }))
        const unloadingPlaces = orderDetails.unloadingPlaces.map(i => ({ value: i, id: generateString() }))

        const now = moment().format('YYYY-MM-DD')
        const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')

        if (now > this.convertDate(orderDetails.startDate)) {
          this.startDate = now
        } else {
          this.startDate = this.convertDate(orderDetails.startDate)
        }

        if (now > this.convertDate(orderDetails.endDate)) {
          this.endDate = tomorrow
        } else {
          this.endDate = this.convertDate(orderDetails.endDate)
        }

        this.form = {
          ...orderDetails,
          loadingPlaces,
          unloadingPlaces
        }

        if (orderDetails.startTime) this.enableLoadHour = true
        if (orderDetails.endTime) this.enableUnloadHour = true

        this.form.limitedCompanies = []
      } catch (e) {
        await catchMarketError(e, true, () => {
          this.$router.push({ name: 'AddVehicle' })
        })
      }
    },
    async submit () {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      this.loadingForm = true
      try {
        const loadingPlaces = this.form.loadingPlaces.map(i => ({ ...i.value }))
        const unloadingPlaces = this.form.unloadingPlaces
          .filter(i => i.value.country && i.value.country.length > 0)
          .map(i => ({ ...i.value }))
        const startTimeFormat = moment(this.startTime).format('HH:mm')
        const endTimeFormat = moment(this.endTime).format('HH:mm')

        if (!this.privateOffer) {
          this.form.limitedCompanies = []
          this.form.limitedCompaniesGroups = []
          this.form.excludedCompaniesGroups = []
        }

        let startDate = null
        let startTime = null

        if (this.enableLoadHour) {
          startDate = moment(this.startDate).format()
          startTime = moment(`${this.startDate} ${startTimeFormat}`).format()
        } else {
          startDate = moment(this.startDate).format()
        }

        let endDate = null
        let endTime = null

        if (this.enableUnloadHour) {
          endDate = moment(this.endDate).format()
          endTime = moment(`${this.endDate} ${endTimeFormat}`).format()
        } else {
          if (this.endDate) {
            endDate = moment(this.endDate).format()
          }
        }

        for (const key in this.form) {
          if (typeof this.form[key] === 'string' && this.form[key].length === 0) {
            this.form[key] = null
          }
        }

        await Service.post('VehicleOrder/Add', {
          model: {
            ...this.form,
            loadingPlaces,
            unloadingPlaces,
            startDate,
            startTime,
            endDate,
            endTime
          }
        })
        await this.$router.push({ name: 'MyVehicles' })
      } catch (e) {
        await catchMarketError(e, true, () => {
          this.$router.go(0)
        })
      } finally {
        this.loadingForm = false
      }
    },
    addLoadingPlaces () {
      this.form.loadingPlaces.push({ value: createNewPlace(), id: generateString() })
    },
    addUnloadingPlaces () {
      this.form.unloadingPlaces.push({ value: createNewPlace(), id: generateString() })
    },
    removeLoadingPlaces (item) {
      this.form.loadingPlaces = this.form.loadingPlaces.filter(l => l.id !== item.id)
    },
    removeUnloadingPlaces (item) {
      this.form.unloadingPlaces = this.form.unloadingPlaces.filter(l => l.id !== item.id)
    },
    blurSelect () {
      if (this.$refs.limitedCompaniesGroups) {
        this.$refs.limitedCompaniesGroups.blur()
      }
      if (this.$refs.excludedCompaniesGroups) {
        this.$refs.excludedCompaniesGroups.blur()
      }
      if (this.$refs.limitedCompanies) {
        this.$refs.limitedCompanies.blur()
      }
      this.$refs.vehicleSizeType.blur()
      this.$refs.currency.blur()
      this.$refs.vehicleType.blur()
    },
    showMoreDetailsClick () {
      this.showMoreDetails = !this.showMoreDetails
    },
    calculateVolumeChange () {
      if (this.form.width > 0 && this.form.height > 0 && this.form.length > 0) {
        this.calculateVolume()
      } else {
        this.resetVolume()
      }
    },
    calculateVolume () {
      const result = this.form.width * this.form.height * this.form.length
      this.form.volume = roundNumber(result)
      this.isCalculatedVolume = true
    },
    resetVolume () {
      this.form.volume = null
      this.isCalculatedVolume = false
    },
    endDateReset () {
      this.setEndTime()
      this.enableUnloadHour = false
    }
  }
}
</script>
<style>
.template-select {
  padding: 0px 10px !important;
}
</style>
